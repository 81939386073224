// 我的差标
import consumer_web_policyConfig_myTravelPolicy from "@/lib/data-service/haolv-default/consumer_web_policyConfig_myTravelPolicy";
export default {
  data() {
    return {
      loading: false,
      info: {},
    };
  },
  computed: {
    
  },
  components: {
    
  },
  methods: {
    init () {
      this.loading = true;
      consumer_web_policyConfig_myTravelPolicy().then((result) => {
        this.info = result.datas;
        this.loading = false;
      }).catch((err) => {
        this.loading = false;
      });
    },
    returnUseCarTimeText(val) {
      // 用车差标类型 1：出差用车 2：加班用车 3：接送服务 4：代叫车 5：定点通勤 6：外勤用车
      const useCarType = [2, 5, 6]
      if (useCarType.indexOf(val) > -1) {
        return true
      } else {
        return false
      }
    },
    returnUseCarLocationType(val) {
      // 用车差标类型 1：出差用车 2：加班用车 3：接送服务 4：代叫车 5：定点通勤 6：外勤用车
      const useCarType = [2, 5]
      if (useCarType.indexOf(val) > -1) {
        return true
      } else {
        return false
      }
    },
    returnWorkLocation(val) {
      // 用车差标类型 1：出差用车 2：加班用车 3：接送服务 4：代叫车 5：定点通勤 6：外勤用车
      const useCarType = [2, 5]
      if (useCarType.indexOf(val) > -1) {
        return true
      } else {
        return false
      }
    },
    returnUsageScenarios(val) {
      // 用车差标类型 1：出差用车 2：加班用车 3：接送服务 4：代叫车 5：定点通勤 6：外勤用车
      const useCarType = [2, 3]
      if (useCarType.indexOf(val) > -1) {
        return true
      } else {
        return false
      }
    },
  },
  created() {},
  mounted() {

  },
  activated() {
    this.init();
  },
  deactivated() {},
  destroyed() {},
  watch: {
    
  },
  filters: {
    
  },
};
